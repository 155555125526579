@font-face {
  font-family: "main-regular";
  src: url("Somar\ Sans\ Font/SomarSans-Regular.otf");
}
body {
  margin: 0;
  font-family: main-regular !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* direction: ltr; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::-webkit-scrollbar {
  width: 0px;
  margin-right: 0px;
  padding: 0px;
  display: none !important;
}
.MuiMenuItem-root {
  font-family: main-regular !important;
}
.MuiInputBase-input,
.MuiTypography-body1,
.MuiButton-label,
.login-form-input,
.MuiAlert-root,
.MuiTolltip-popper {
  font-family: main-regular !important;
}
.messageBarButtons .MuiIconButton-root{
  border-radius: 15px !important;
  font-family: 'main-regular';
  padding: 7px 12px;
}
.messageBarButtons .MuiIconButton-root .MuiIconButton-label{
  font-size: 17px;
  gap: 10px;
  flex-direction: row-reverse;
}
/* .makeStyles-closeBtn-141{
  left: 0 !important;
  right: unset !important;
} */
.MuiAlert-icon {
  margin-left: 12px;
  margin-right: 0;
}
.MuiAlert-root {
  width: 90%;
  margin: 0 auto 10px auto;
}


/* LTR */
body[dir="ltr"] .switchMarginCustom {
  left: unset;
  right: -5px;
}
body[dir="ltr"] .closeButtonCustom,
body[dir="ltr"] .infoItemCustom span:nth-child(3) {
  right: 8px;
  left: unset;
}
body[dir="ltr"] .infoItemCustom span:nth-child(2) {
  margin-right: unset;
  margin-left: 16px;
}
body[dir="ltr"] .gInputBaseWidthCustom,
body[dir="ltr"] .gNameTextCustom,
body[dir="ltr"] .nameTextCustom {
  margin-right: unset;
  margin-left: 10px;
}
body[dir="ltr"] .infoSwitchItemCustom {
  padding-left: 14px;
  padding-right: unset;
}
body[dir="ltr"] .menuBtnCustom img,
body[dir="ltr"] .MuiTypography-noWrap img,
body[dir="ltr"] .MuiTypography-noWrap svg{
  margin-right: 10px;
  margin-left: unset !important;
}
body[dir="ltr"] .notifyPrayTitleCustom,
body[dir="ltr"] .infoBtnTextCustom {
  margin-right: unset;
  margin-left: 5px;
  text-align: left;
}
body[dir="ltr"] .chatlist-header-PresenceStatus {
  left: 35px;
  right: unset;
}
body[dir="ltr"] .gNextImgCustom {
  transform: rotate(180deg);
}
body[dir="ltr"] .contentBoxCustom {
  padding: 10px 0 0px 20px;
}
body[dir="ltr"] .footerBtnCustom {
  text-align: right;
}
body[dir="ltr"] .secondSpanCustom {
  margin-right: 23px;
  margin-left: unset;
}
body[dir="ltr"] .imgBoxCustom {
  left: 45px;
  right: unset;
}
.muteImgstyleCustom{
  margin-right: 2px;
}
body[dir="ltr"] .myself-menu .MuiMenuItem-root{
  padding-right: 16px;
  padding-left: 8px;
}

body[dir="ltr"] .rightBtnCustom{
  margin-left: 20px;
  margin-right: unset;
}
body[dir="ltr"] .byFriendMessage{
  transform: translateX(100%);
  right: -8px;
}
body[dir="ltr"] .bySelfMessage {
  left: -8px;
  transform: translateX(-100%);
  right: unset;
}
body[dir="ltr"] .textReactionCustom{
  right: -6px;
  transform: translateX(100%);
}
body[dir="ltr"] .textBodybyFriend{
  border-radius: 16px 16px 16px 4px;
}
body[dir="ltr"] .textBodybySelf {
  border-radius: 16px 16px 4px;
}
/* RTL */
body[dir="rtl"] .MuiSelect-select.MuiSelect-select{
  padding-left: 24px;
  padding-right: 10px;
}
body[dir="rtl"] .MuiSelect-icon{
  right: unset;
  left: 0;
}
body[dir="rtl"] .byFriendMessage{
  left: -8px;
}
body[dir="rtl"] .textBodybyFriend{
  margin: 0px 10px 10px 0px;
}