.tlp-header{
    display: flex;
    padding: 0 4px 0 24px;
    height: 60px;
    background: #fff;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    align-items: center;
}
.tlp-header-title{
    font-size: 18px;
    line-height: 60px;
    font-weight: 600;
    max-width: 476px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.tlp-header-icon{
    margin-right: 20px;
    cursor: pointer;
}
.tlp-header-icon-search{
    display: inline-block;
    margin-top: 20px;
    height: 19px;
    width: 19px;
}
.list-con{
    padding-top: 2px;
    max-height: 710px;
    overflow-y: scroll;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    
}
.list-con::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}
.list-item{
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 6px 8px 0 8px;
    width: 524px;
    height: 54px;
    line-height: 54px;
    border-radius: 16px;
}
.list-item:hover{
    background: #F4F5F7;
}
.user-info{
    display: flex;
    margin-left: 8px;
    line-height: 54px;
}
.avatar{
    margin-top: 7px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
}
.username{
    margin-left: 16px;
    line-height: 54px;
    font-size: 16px;
    font-weight: 600;
    color: #0D0D0D;
}
.user-role{
    display: flex;
    margin-right: 15px;
}
.role{
    font-size: 14px;
    color: #999;
}
.edit{
    display: block;
    margin-top: 17px;
    margin-left: 20px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: url("../images/edit.png") center center no-repeat;
    cursor: pointer;
}
.edit:hover{
    background: url("../images/edit.png") #fff center center no-repeat;
}
.edit-con:hover .remove-con{
    display: block;
}
.remove-con{
    width: 240px;
    background: #fff;
    border-radius: 12px;
    cursor: pointer;
}
.remove{
    display: flex;
    margin: 8px;
    height: 38px;
    width:224px;
    background: #F4F5F7;
    border-radius: 8px;
}
.remove-icon{
    display: block;
    margin-top: 4px;
    height: 30px;
    width: 30px;
    background: url("../images/DisbandThread.png") center center no-repeat;
}
.remove-text{
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #FF14CC;
    line-height: 38px;
}
.list-empty{
    margin-top: 150px;
    width: 100%;
    text-align: center;
}