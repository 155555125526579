.login-container {
    background-image: url('../assets/background-min.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* min-width: 600px; */
    min-height: 698px;
    background-position: center;
}

.login-form {
    min-width: 300px;
    min-height: 460px;
    max-width: 380px;
    max-height: 614px;
    height: 566px;
    width: 380px;
    background-color: #FFF;
    border: 1px solid #fff;
    border-radius: 12px;
    box-shadow: 0 24 36 0 rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.login-form-icon {
    background-image: url('../assets/favicon.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
    margin-top: 15%;
    margin-bottom: 10px;
}

.login-form-AC {
    background-image: url('../assets/logo.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 180px;
    height: 27px;
    margin-bottom: 54px;
}

.login-form-notice {
    background-image: url('../assets/red-login@2x.png');
    background-size: 14px;
    background-position: 2px;
    background-repeat: no-repeat;
    padding-left: 18px;
    max-width: 85%;
    color: #000;
    font-size: 14px;
    overflow: hidden;
    word-break: break-all;
    height: 18px;
    margin: 2% 0;
    position: absolute;
    top: 220px;
    line-height: 16px;
}

.login-form-input {
    width: 85%;
    height: 48px;
    background-color: #F2F3F3;
    border: 1px solid #F2F3F3;
    border-radius: 24px;
    /* margin: 2% 0; */
    font-size: 14px;
    color: #000;
    padding: 0 48px 0 19px;
    box-sizing: border-box;
    outline: 0 none;
}

.button {
    background-color: #114EFF;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 0 19px;
}

.button:active {
    background-color: #093ed9;
}

.button:disabled {
    background-color: RGBA(17, 78, 255, 0.5);
}

.login-copyright {
    font-size: 16px;
    color: #fff;
    position: relative;
    /* bottom: -90px; */
    bottom: -10%;
}

.main-container {
    flex: 1;
}
.sign-up-box {
    color: #999;
    text-align: center;
    font-size: 14px;
}
.sign-up {
    color: #114EFF;
    margin-left: 5px;
    cursor: pointer;
}
.sign-up-form {
    height: 614px;
}
.input-box {
    position: relative;
    width: 100%;
    margin-bottom: 18px;
}
.close-btn {
    position: absolute !important;
    right: 40px;
    top: 10px;
    cursor: pointer;
    width: 28px;
    height: 28px;
    vertical-align: middle;
    border-radius: 50%;
}
.loading-box {
    width: 100%;
    position: relative;
    margin-bottom: 31px;
}
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.loading-img {
    animation: rotation 1s linear infinite;
    width: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -2%;
    margin-top: -10px;
}
.mysignupdialog .MuiBackdrop-root {
    backdrop-filter: blur(12px);
    background: rgba(0, 0, 0, .2);
}
.mysignupdialog .MuiDialog-paper {
    border-radius: 12px;
}
.mysignupdialog .Component-closeButton-3 {
    color: #000;
}
.footer-btn {
    text-align: right;
    height: 100px;
    width: 540px;
    border-radius: 12px;
    padding-top: 50px;
    box-sizing: border-box;
}
.footer-btn span {
    height: 36px;
    width: 84px;
    border-radius: 26px;
    display: inline-block;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 36px;
    text-align: center;
    color: #000000;
    cursor: pointer;
}
.footer-btn span:nth-child(2) {
    color: #FFFFFF;
    margin-right: 23px;
    background: #114EFF;
}

.loader {
    height : 8px;
    width  : 36px; /* (6 * <margin: 2px>) + (3 * <width: 8px>) */
}

.loader-box {
    display                   : inline-block;
    height                    : 18px;
    width                     : 30px;
    margin                    : 0px 2px;
    background-color          : rgb(0,146,255);
    animation-name            : fadeOutIn;
    animation-duration        : 500ms;
    animation-iteration-count : infinite;
    animation-direction       : alternate;
}

.loader-box:nth-child(1) { animation-delay: 250ms; } /* (1/2) * <animation-duration: 500ms */
.loader-box:nth-child(2) { animation-delay: 500ms; } /* (2/2) * <animation-duration: 500ms */
.loader-box:nth-child(3) { animation-delay: 750ms; } /* (3/2) * <animation-duration: 500ms */

@keyframes fadeOutIn {
    0%   { background-color : rgba(0,146,255,1); }
    100% { background-color : rgba(0,146,255,0); }
}


.loader {
    margin-top: 15px;
    border: 12px solid #f3f3f3;
    border-radius: 50%;
    border-top: 12px solid #204687;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }